import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Modal,
  Fade,
  TextField,
  Button,
  Box,
  Backdrop,
} from "@mui/material";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "20px",
};

export default function Projects() {
  const { organizationId } = useParams();
  const location = useLocation();

  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    setIsLoading(true);
    axios
      .get(`${BASE_URL}/organizations/${organizationId}/projects/all`)
      .then((response) => {
        setProjects(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addProject = () => {
    setIsAdding(true);
    axios
      .post(`${BASE_URL}/organizations/${organizationId}/project`, {
        name: projectName,
      })
      .then((response) => {
        setIsAdding(false);
        setIsDialogOpen(false);
        setProjectName("");
        getProjects();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="container">
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            padding: "20px",
          }}
        >
          <h4>Cool! What Project are you working on ?</h4>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {projects.map((element) => (
              <Link
                to={`/writing`}
                state={{
                  project_name: element.name,
                  organization_name: location.state.organization_name,
                }}
              >
                <Card
                  className="gradient-card"
                  sx={{
                    width: "150px",
                    height: "150px",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <CardContent
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      color: "#212020",
                    }}
                  >
                    <strong style={{ textDecoration: "none" }}>
                      {element.name}
                    </strong>
                  </CardContent>
                </Card>
              </Link>
            ))}
            <Card
              className="gradient-card"
              sx={{
                width: "150px",
                height: "150px",
                display: "grid",
                placeContent: "center",
              }}
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              <CardContent
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  color: "#212020",
                }}
              >
                <strong style={{ textDecoration: "none" }}>
                  + Add Project
                </strong>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
      <Modal
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isDialogOpen}>
          <Box sx={style}>
            <TextField
              required
              variant="outlined"
              label="Project Name"
              size="small"
              onChange={(e) => setProjectName(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              disabled={isAdding}
              style={{ marginTop: "10px" }}
              onClick={addProject}
            >
              Add
              {isAdding ? <CircularProgress size={20} /> : ""}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
