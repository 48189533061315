import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import writingAnimation from "../../assets/writing.json";
import { SOCKET_URL } from "../../utils/Constants";

export default function Writing() {
  const location = useLocation();

  const socket = io(SOCKET_URL);

  const [steps, setSteps] = useState("");
  const [documentText, setDocumentText] = useState("");
  const [model, setModel] = useState("gpt-3.5-turbo");
  const [temperature, setTemperature] = useState("0.0");
  const [isWriting, setIsWriting] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: writingAnimation,
  };

  const startWriting = () => {
    if (window && window.parent) {
      window.parent.postMessage({ functionName: "getDocumentContents" }, "*");
    }
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.functionName === "getDocumentContents") {
        setDocumentText(event.data.contents);
      }
    });
  }, []);

  useEffect(() => {
    if (documentText !== "") {
      let documentContentArray = documentText.split("::references::");
      let outline = documentContentArray[0];
      let references = documentContentArray[1];
      setIsWriting(true);
      socket.emit("start", {
        outline,
        references,
        project_name: location.state.project_name,
        organization_name: location.state.organization_name,
        model,
        temperature,
      });
      socket.on("step_change", (data) => {
        setSteps(steps + data.title + "\n");
        if (data.data) {
          writeToDocument(data.data);
        }
      });
    }
  }, [documentText]);

  const writeToDocument = (content) => {
    if (window && window.parent) {
      window.parent.postMessage(
        { functionName: "writeToDocument", content },
        "*"
      );
    }
  };

  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        padding: "20px",
      }}
    >
      {!isWriting ? (
        <>
          <h1>And that's it!</h1>
          <ul>
            <li>Put your input materials in the document</li>
            <li>Play around with the settings below if you are bored</li>
            <li>Click the start button</li>
            <li>Grab a coffee!</li>
          </ul>
          <FormControl fullWidth size="small">
            <InputLabel id="model-select-label">Model</InputLabel>
            <Select
              labelId="model-select-label"
              id="model-select"
              label="Model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            >
              <MenuItem value="gpt-3.5-turbo">GPT 3.5 Turbo</MenuItem>
              <MenuItem value="gpt-4">GPT 4</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            size="small"
            variant="outlined"
            label="Temperature"
            inputMode="number"
            value={temperature}
            onChange={(e) => setTemperature(e.target.value)}
            fullWidth
          />
          <Button variant="contained" onClick={startWriting}>
            Start
          </Button>
        </>
      ) : (
        <>
          <Lottie options={defaultOptions} height={200} width={200} />
          <pre
            style={{
              width: "80vw",
              height: "60vh",
              overflow: "scroll",
              background: "black",
              color: "white",
              padding: "10px",
              fontFamily:
                "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New",
            }}
          >
            {steps}
          </pre>
        </>
      )}
    </div>
  );
}
