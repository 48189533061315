import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Welcome from "./pages/welcome";
import Organizations from "./pages/organizations";
import Projects from "./pages/projects";
import Writing from "./pages/writing.jsx";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#80CED7",
      },
      secondary: {
        main: "#E5D9F2",
      },
    },
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Welcome />,
    },
    {
      path: "/organizations",
      element: <Organizations />,
    },
    {
      path: "/organizations/:organizationId/projects",
      element: <Projects />,
    },
    {
      path: "/writing",
      element: <Writing />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}></RouterProvider>
    </ThemeProvider>
  );
}

export default App;
